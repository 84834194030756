import { get, post } from '@common-src/service/request';
import { dateFormat } from '@common-src/filter';

const URL_PATH = `${BIBIZ_BASE_REQUEST_PATH}/lightingSystem`;
class LightIndexService {
    /**
     * 查询告警数量
     * @returns
     */
    async getElectricityCount() {
        const res = await get(`${URL_PATH}/electricity/count`);
        return res;
    }

    /**
     * 设备总数/异常设备
     * @returns
     */
    async getFacilityCount() {
        const url = `${URL_PATH}/facility/rts/count`;
        const res = await get(url);
        return res;
    }

    /**
     * 正在告警数量
     * @returns
     */
    async getAlarmActiveCount() {
        const url = `${URL_PATH}/alarm/rts/ACTIVE/count`;
        const res = await get(url);
        return res;
    }

    /**
     * 设备状态统计
     * @returns
     */
    async getFacilityStatusCount() {
        const url = `${URL_PATH}/facility/rts/status/count`;
        const res = await get(url);
        return {
            columns: ['name', 'value'],
            rows: res
        };
    }

    /**
     * 近7天告警趋势
     * @returns
     */
    async getLast7DayAlarmCount(model: any) {
        const url = `${URL_PATH}/alarm/hts/countTrends`;
        const res = await post(url, model?.toService());
        return {
            columns: ['ts', '数量'],
            rows: _.map(res, item => {
                return {
                    ts: dateFormat(item.ts, 'MM-DD'),
                    '数量': item.value
                };
            })
        };
    }

    /**
     * 月度用电统计
     * @returns
     */
    async getElectricityMonthData(params?: any) {
        const url = `${URL_PATH}/electricity/month/count`;
        const res = await get(url, params);
        return {
            data: {
                columns: ['ts', '用电量'],
                rows: _.map(res, item => {
                    return {
                        ts: dateFormat(item.ts, 'YYYY-MM'),
                        '用电量': item.count
                    };
                })
            },
            settings: {
                type: 'histogram',
                yAxisName: ['kWh']
            }
        };
    }
}

export default new LightIndexService();
