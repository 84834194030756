import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { assignDefaultExtend } from '@common-src/service/report';
import { QueryModel } from '@common-src/model/query-model';
import ParkingFacilityService from '../service/parking-facility';
import { PeriodTypeEnum, SubSystemType } from '@common-src/model/enum';
import { post, download } from '@common-src/service/request';
import { dateFormat, percentFormat } from '@common-src/filter';
import { CUSTOMER_FLOW_TYPE_OPTIONS } from './customer-flow-location';
import moment, { Moment } from 'moment';
import { changeTreeDataFieldName, timeTransformData } from '../data-factory';

export class CustomerFlowTrendQueryModel extends QueryModel {
    constructor(type?: Array<string>, isDateRange: boolean = false) {
        super();
        this.type = type || ['RETAINEDFLOW'];
        this.isDateRange = isDateRange;
    }
    isDateRange: boolean;
    @QueryControl({
        label: '区域',
        placeholder: '全部区域',
        type: QueryControlType.TREE_SELECT,
        optionsPromise: ParkingFacilityService.getLocationTree,
        optionsPromiseParam: SubSystemType.CUSTOMER_FLOW,
        mode: 'multiple',
        selectChildren: false,
        span: 10,
        // defaultValue: '[0].value',
        hasAllOption: false,
        required: false,
        disableSelectCross: true
    })
    locationIds: string = undefined;

    @QueryControl({
        label: '时间',
        type: QueryControlType.SELECT_DATE,
        isRange: false,
        showTime: false,
        span: 6,
        required: true,
        invisibleFunction: (model: CustomerFlowTrendQueryModel) => model.isDateRange
    })
    date: Moment = moment();

    @QueryControl({
        label: '时间',
        type: QueryControlType.SELECT_DATE,
        isRange: true,
        showTime: false,
        span: 6,
        required: true,
        invisibleFunction: (model: CustomerFlowTrendQueryModel) => !model.isDateRange
    })
    dateRange: Array<Moment> = JTL.CONSTANT.LAST_7_DAY();

    @QueryControl({
        label: '客流类型',
        type: QueryControlType.SELECT,
        options: CUSTOMER_FLOW_TYPE_OPTIONS,
        span: 6,
        mode: 'multiple',
        required: true
    })
    type: Array<string> = undefined;

    toService() {
        const params: any = {
            locationIds: this.locationIds,
            type: this.type
        };
        if (this.isDateRange) {
            params.startTime = this.dateRange[0].startOf('day').toDate().getTime();
            params.endTime = this.getEndTime(this.dateRange[1]);
            params.intervalType = PeriodTypeEnum.DAY;
        } else {
            params.startTime = this.date.startOf('day').toDate().getTime();
            params.endTime = this.getEndTime(this.date);
            params.intervalType = PeriodTypeEnum.HOUR;
        }
        return params;
    }
}

export async function CustomerFlowTrendService(model: CustomerFlowTrendQueryModel) {
    // const res = await CustomerFlowIndexService.getTrendCount(model?.toService());
    const res = await post(`${BIBIZ_BASE_REQUEST_PATH}/custFlowSystem/hts/trend`, model?.toService());
    const columns = [
        { code: 'ts', name: '时间' }
    ];
    let timeFormat = null;
    let pageTitle = null;
    let chartType = null;
    if (model.isDateRange) {
        timeFormat = 'MM-DD';
        pageTitle = '多日客流趋势';
        chartType = 'histogram';
    } else {
        timeFormat = 'MM-DD HH:mm';
        pageTitle = '今天客流趋势';
        chartType = 'line';
    }
    const tableColumns: Array<any> = [
        {
            title: '区域',
            customRender: (text, record, index) => {
                return record.locationName || dateFormat(record.ts, timeFormat);
            },
            orderIndex: 0
        }
    ];
    _.forEach(model.type, (item, index) => {
        const typeModel = _.find(CUSTOMER_FLOW_TYPE_OPTIONS, option => option.value === item);
        columns.push({ code: typeModel.dataIndex, name: typeModel.name });
        tableColumns.push({
            title: typeModel.name,
            dataIndex: typeModel.dataIndex,
            orderIndex: index + 1
        });
        if (item !== 'RETAINEDFLOW') {
            tableColumns.push({
                title: `同比(${typeModel.name})`,
                dataIndex: typeModel.yoyRateDataIndex,
                customRender: (text, record, index) => {
                    return percentFormat(text);
                },
                orderIndex: (index + 1) * 20
            });
            tableColumns.push({
                title: `环比(${typeModel.name})`,
                dataIndex: typeModel.monRateDataIndex,
                customRender: (text, record, index) => {
                    return percentFormat(text);
                },
                orderIndex: (index + 1) * 30
            });
        }
    });
    const chartOptions = {
        title: pageTitle,
        data: timeTransformData(res, columns, timeFormat, 'locationName'),
        settings: {
            type: chartType
        },
        tableRowKey: (record) => {
            return record.locationId || record.ts;
        },
        tableExpandedRowKeys: _.map(res, item => item.locationId),
        tableRows: changeTreeDataFieldName(res, 'data', 'children'),
        tableColumns: _.orderBy(tableColumns, 'orderIndex')
    };
    return assignDefaultExtend(chartOptions);
}

export async function CustomerFlowTrendExportService(queryModel: CustomerFlowTrendQueryModel) {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/custFlowSystem/export/distribution`;
    const res = await download(url, queryModel?.toService(), 'post');
    return res;
}
