import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { assignDefaultExtend } from '@common-src/service/report';
import { QueryModel } from '@common-src/model/query-model';
import ParkingFacilityService from '../service/parking-facility';
import { SubSystemType } from '@common-src/model/enum';
import CustomerFlowIndexService from '../service/customer-flow';
import { download } from '@common-src/service/request';
import { ChartRingExtend } from '@common-src/mixins/report-chart-base';
import { percentFormat } from '@common-src/filter';

export const CUSTOMER_FLOW_TYPE_OPTIONS = [
    { value: 'INFLOW', name: '进客流', dataIndex: 'inCount', yoyRateDataIndex: 'yoyInRate', monRateDataIndex: 'momInRate' },
    { value: 'OUTFLOW', name: '出客流', dataIndex: 'outCount', yoyRateDataIndex: 'yoyOutRate', monRateDataIndex: 'momOutRate' },
    { value: 'RETAINEDFLOW', name: '留存客流', dataIndex: 'retainedCount' }
];

export class CustomerFlowLocationQueryModel extends QueryModel {
    constructor(type?: string) {
        super();
        this.type = type || 'RETAINEDFLOW';
    }
    @QueryControl({
        label: '区域',
        placeholder: '全部区域',
        type: QueryControlType.TREE_SELECT,
        optionsPromise: ParkingFacilityService.getLocationTree,
        optionsPromiseParam: SubSystemType.CUSTOMER_FLOW,
        mode: 'multiple',
        selectChildren: false,
        span: 10,
        // defaultValue: '[0].value',
        hasAllOption: false,
        required: false,
        disableSelectCross: true
    })
    locationIds: string = undefined;

    @QueryControl({
        label: '客流类型',
        type: QueryControlType.SELECT,
        options: CUSTOMER_FLOW_TYPE_OPTIONS,
        span: 6,
        required: true
    })
    type: string = undefined;

    toService() {
        const params: any = {
            locationIds: this.locationIds,
            type: this.type
        };
        return params;
    }
}

export async function CustomerFlowLocationService(model: CustomerFlowLocationQueryModel) {
    const res = await CustomerFlowIndexService.getLocationCount(model?.toService());
    const chartOptions = {
        title: '今天客流分布',
        data: res,
        settings: {
            type: 'ring'
        },
        extend: ChartRingExtend,
        tableRowKey: 'name',
        tableColumns: [
            {
                title: '区域',
                dataIndex: 'name'
            },
            {
                title: _.get(_.find(CUSTOMER_FLOW_TYPE_OPTIONS, item => item.value === model.type), 'name'),
                dataIndex: '数量'
            },
            {
                title: '占比',
                dataIndex: 'rate',
                customRender: (text, record, index) => {
                    return percentFormat(text);
                }
            }
        ]
    };
    return assignDefaultExtend(chartOptions);
}

export async function CustomerFlowLocationExportService(queryModel: CustomerFlowLocationQueryModel) {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/custFlowSystem/export/distribution`;
    const res = await download(url, queryModel?.toService(), 'post');
    return res;
}
