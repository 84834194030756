import { get, post } from '@common-src/service/request';
import { dateFormat } from '@common-src/filter';
import { ElevatorAlarmTrendQueryModel } from '../model/elevator-alarm-trend';
import { AccessControlRtsTypeQueryModel } from '../model/access-control-rts-type';

const URL_PATH = `${BIBIZ_BASE_REQUEST_PATH}/acs`;
class AccessControlIndexService {
    /**
     * 园内人数
     * @returns
     */
    async getRtsCount() {
        const res = await get(`${URL_PATH}/rts/count`);
        return res;
    }

    /**
     * 查询告警数量
     * @returns
     */
    async getAlarmCount() {
        const res = await get(`${URL_PATH}/alarm/rts/count`);
        return res;
    }

    /**
     * 设备总数/异常设备
     * @returns
     */
    async getFacilityCount() {
        const url = `${URL_PATH}/facility/rts/count`;
        const res = await get(url);
        return res;
    }

    /**
     * 园内人数统计
     * @returns
     */
    async getRtsTotalCount() {
        const url = `${URL_PATH}/rts/num`;
        const res = await get(url);
        return {
            columns: ['ts', '数量'],
            rows: _.map(res, item => {
                return {
                    ts: dateFormat(item.ts, 'MM-DD'),
                    '数量': item.value
                };
            })
        };
    }

    /**
     * 人员类型统计
     * @returns
     */
    async getRtsTypeCount(model?: AccessControlRtsTypeQueryModel) {
        const url = `${URL_PATH}/rts/types`;
        const res = await post(url, model.toService());
        return {
            columns: ['name', 'value', 'rate'],
            rows: res
        };
    }

    /**
     * 告警状态统计
     * @returns
     */
    async getAlarmActive() {
        const url = `${URL_PATH}/alarm/hts/ACTIVE/count`;
        const res = await post(url);
        return res;
    }

    /**
     * 告警状态统计
     * @returns
     */
    async getAlarmStatusCount() {
        const url = `${URL_PATH}/alarm/rts/STATUS/count`;
        const res = await get(url);
        return {
            columns: ['name', 'value'],
            rows: [
                { name: '已处理', value: res.solvedCount },
                { name: '未处理', value: res.unsolvedCount }
            ]
        };
    }

    /**
     * 近7天告警趋势
     * @returns
     */
    async getLast7DayAlarmCount(model: ElevatorAlarmTrendQueryModel) {
        const url = `${URL_PATH}/alarm/hts/countTrends`;
        const res = await post(url, model.toService());
        return {
            columns: ['ts', '数量'],
            rows: _.map(res, item => {
                return {
                    ts: dateFormat(item.ts, 'MM-DD'),
                    '数量': item.value
                };
            })
        };
    }
}

export default new AccessControlIndexService();
