import { get, post } from '@common-src/service/request';
import { ParkingTrafficFlowQueryModel } from '../model/parking-traffic-flow-model';
import { ParkingTrafficFlowRankingQueryModel } from '../model/parking-traffic-flow-ranking-model';
import { dateFormat } from '@common-src/filter';

const URL_PATH = `${BIBIZ_BASE_REQUEST_PATH}/parkingSystem`;
class ParkingIndexService {
    /**
     * 设备总数/异常设备
     * @returns
     */
    async getFacilityCount(params?:any) {
        const url = `${URL_PATH}/facility/rts/count`;
        const res = await get(url);
        const facilityFaultCount = res.faultCount;
        const facilityNormalCount = res.totalCount - facilityFaultCount;
        const data = {
            columns: ['name', 'count'],
            rows: [
                { name: '正常', count: facilityNormalCount },
                { name: '异常', count: facilityFaultCount }
            ],
            ...res
        };
        return data;
    }
    /**
     * 今日累计驶入车次
     * @returns
     */
    async getTrafficFlowCount(params?:any) {
        const url = `${URL_PATH}/parkingGate/rts/count`;
        const res = await get(url);
        return res;
    }

    /**
     * 获取车位使用实时统计
     * @returns
     */
    async getParkingSpaceUsage(params?:any) {
        const url = `${URL_PATH}/parkingSpace/rts/usage`;
        const res = await get(url);
        return res;
    }

    /**
     * 告警状态统计
     * @returns
     */
    async getAlarmCount(params?:any) {
        const url = `${URL_PATH}/alarm/rts/STATUS/count`;
        const res = await get(url);
        return res;
    }

    /**
     * 车流量统计
     * @returns
     */
    async getTrafficFlow(model?: ParkingTrafficFlowQueryModel) {
        const url = `${URL_PATH}/parkingGate/hts/trafficFlow`;
        const res = await post(url, model?.toService());
        res.locationName = '合计';
        res.locationId = 'id_all';
        return {
            response: [res],
            columns: ['ts', 'inCount', 'outCount'],
            rows: _.map(_.get(res, 'data'), item => {
                item.ts = dateFormat(item.ts, 'MM-DD HH:mm');
                return item;
            })
        };
    }

    /**
     * 出入口统计
     * @returns
     */
    async getParkingGate(params?:any) {
        const url = `${URL_PATH}/parkingGate/rts/typeComparison`;
        const res = await get(url);
        const data = {
            columns: ['name', 'count'],
            rows: [
                { name: '出口', count: _.get(res, 'exitCount', 0) },
                { name: '入口', count: _.get(res, 'entranceCount', 0) }
            ],
            ...res
        };
        return data;
    }

    /**
     * 出入口流量排名
     * @returns
     */
    async getParkingGateRank(model?: ParkingTrafficFlowRankingQueryModel) {
        const url = `${URL_PATH}/parkingGate/hts/rank`;
        const res = await post(url, model?.toService());
        res.locationName = '合计';
        res.locationId = 'id_all';
        const rows = _.map(_.orderBy(_.get(res, 'children'), 'value'), item => {
            item.rate = item.value / res.totalCount;
            return item;
        });
        const maxValue = _.get(res, 'children[0].value');
        return {
            data: {
                columns: ['name', 'value'],
                rows
            },
            settings: {
                type: 'bar',
                labelMap: { value: '数量' }
            },
            xAxis: {
                type: 'value',
                max: maxValue + maxValue * 0.2,
                axisTick: {
                    show: false
                },
                axisLine: {
                    show: false
                },
                splitLine: {
                    show: false
                },
                axisLabel: {
                    show: false
                }
            },
            yAxis: {
                type: 'category',
                name: '车次',
                data: _.map(rows, item => item.name),
                axisTick: {
                    show: false
                },
                axisLine: {
                    show: false
                },
                splitLine: {
                    show: false
                }
            },
            extend: {
                series: {
                    showBackground: true,
                    label: {
                        show: true,
                        position: 'right'
                    }
                }
            },
            height: rows.length * 30
        };
    }

    /**
     * 告警状态统计
     * @returns
     */
    async getRuleEventCount(params?:any) {
        const url = `${URL_PATH}/alarm/rts/STATUS/count`;
        const res = await get(url);
        return {
            columns: ['name', 'count'],
            rows: [
                { name: '未处理', count: _.get(res, 'unsolvedCount') || 0 },
                { name: '已处理', count: _.get(res, 'solvedCount') || 0 }
            ]
        };
    }
}

export default new ParkingIndexService();
