import { post, download } from '@common-src/service/request';
import { assignDefaultExtend } from '@common-src/service/report';
import { percentFormat } from '@common-src/filter';
import { getTreeIdByDepth } from '../data-factory';
import { QueryModel } from '@common-src/model/query-model';
import ParkingFacilityService from '../service/parking-facility';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { SubSystemType } from '@common-src/model/enum';

export class ComputerRoomCapacityQueryModel extends QueryModel {
    @QueryControl({
        label: '区域',
        placeholder: '全部区域',
        type: QueryControlType.TREE_SELECT,
        optionsPromise: ParkingFacilityService.getLocationTree,
        optionsPromiseParam: SubSystemType.COMPUTER_ROOM,
        mode: 'multiple',
        selectChildren: false,
        span: 10,
        // defaultValue: '[0].value',
        required: false
    })
    locationIds: Array<string> = undefined;

    toService() {
        return {
            locationIds: this.locationIds
        };
    }
}

export async function ComputerRoomCapacityService(model: ComputerRoomCapacityQueryModel) {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/computerRoomSystem/rts/capacity/count`;
    const res = await post(url, model?.toService());
    const allData = [
        {
            locationId: 'u',
            locationName: 'U位',
            title: 'U位 | 容量统计',
            type: 'jtl-custom-gauge',
            rateU: _.get(res, '[0].data.rateU'),
            remainU: _.get(res, '[0].data.remainU'),
            data: _.get(res, '[0].data.useURate'),
            children: _.map(_.get(res, '[0].children'), item => {
                return {
                    locationId: item.locationId,
                    locationName: item.locationName,
                    rateU: _.get(item, 'data.rateU'),
                    remainU: _.get(item, 'data.remainU'),
                    useURate: _.get(item, 'data.useURate')
                };
            }),
            tableColumns: [
                {
                    title: '区域',
                    dataIndex: 'locationName'
                },
                {
                    title: '额定U位',
                    dataIndex: 'rateU'
                },
                {
                    title: '未用U位',
                    dataIndex: 'remainU'
                },
                {
                    title: '使用率',
                    dataIndex: 'useURate',
                    customRender: (text, record, index) => {
                        return percentFormat(text);
                    }
                }
            ],
            percentDesc: '使用率',
            chartDesc: [
                {
                    name: '额定U位',
                    value: _.get(res, '[0].data.rateU')
                },
                {
                    name: '未用U位',
                    value: _.get(res, '[0].data.remainU')
                }
            ]
        },
        {
            locationId: 'power',
            locationName: '功率',
            title: '功率 | 容量统计',
            type: 'jtl-custom-gauge',
            data: _.get(res, '[0].data.usePowerRate'),
            ratePower: _.get(res, '[0].data.ratePower'),
            remainPower: _.get(res, '[0].data.remainPower'),
            children: _.map(_.get(res, '[0].children'), item => {
                return {
                    locationId: item.locationId,
                    locationName: item.locationName,
                    ratePower: _.get(item, 'data.ratePower'),
                    remainPower: _.get(item, 'data.remainPower'),
                    usePowerRate: _.get(item, 'data.usePowerRate')
                };
            }),
            tableColumns: [
                {
                    title: '区域',
                    dataIndex: 'locationName'
                },
                {
                    title: '额定功率',
                    dataIndex: 'ratePower'
                },
                {
                    title: '未用功率',
                    dataIndex: 'remainPower'
                },
                {
                    title: '使用率',
                    dataIndex: 'usePowerRate',
                    customRender: (text, record, index) => {
                        return percentFormat(text);
                    }
                }
            ],
            percentDesc: '使用率',
            chartDesc: [
                {
                    name: '额定功率',
                    value: _.get(res, '[0].data.ratePower')
                },
                {
                    name: '未用功率',
                    value: _.get(res, '[0].data.remainPower')
                }
            ]
        },
        {
            locationId: 'loadBear',
            locationName: '承重',
            title: '承重 | 容量统计',
            type: 'jtl-custom-gauge',
            data: _.get(res, '[0].data.useLoadBearRate'),
            rateLoadBear: _.get(res, '[0].data.rateLoadBear'),
            remainLoadBear: _.get(res, '[0].data.remainLoadBear'),
            children: _.map(_.get(res, '[0].children'), item => {
                return {
                    locationId: item.locationId,
                    locationName: item.locationName,
                    rateLoadBear: _.get(item, 'data.rateLoadBear'),
                    remainLoadBear: _.get(item, 'data.remainLoadBear'),
                    useLoadBearRate: _.get(item, 'data.useLoadBearRate')
                };
            }),
            tableColumns: [
                {
                    title: '区域',
                    dataIndex: 'locationName'
                },
                {
                    title: '额定承重',
                    dataIndex: 'rateLoadBear'
                },
                {
                    title: '未用承重',
                    dataIndex: 'remainLoadBear'
                },
                {
                    title: '使用率',
                    dataIndex: 'useLoadBearRate',
                    customRender: (text, record, index) => {
                        return percentFormat(text);
                    }
                }
            ],
            percentDesc: '使用率',
            chartDesc: [
                {
                    name: '额定承重',
                    value: _.get(res, '[0].data.rateLoadBear')
                },
                {
                    name: '未用承重',
                    value: _.get(res, '[0].data.remainLoadBear')
                }
            ]
        }
    ];
    const chartOptions = {
        data: allData,
        tableRowKey: 'locationId',
        // tableExpandedRowKeys: getTreeIdByDepth(res.children, 'locationId', 1),
        tableExpandedRowKeys: _.map(allData, item => item.locationId),
        tableRows: allData,
        tableColumns: [
            {
                title: '区域',
                customRender: (text, record, index) => {
                    return _.get(record, 'name') || _.get(record, 'locationName');
                }
            },
            {
                title: '额定',
                dataIndex: 'rateCount',
                customRender: (text, record, index) => {
                    return _.get(record, 'rateU') || _.get(record, 'ratePower') || _.get(record, 'rateLoadBear');
                }
            },
            {
                title: '未用',
                customRender: (text, record, index) => {
                    return _.get(record, 'remainU') || _.get(record, 'remainPower') || _.get(record, 'remainLoadBear');
                }
            },
            {
                title: '使用率',
                customRender: (text, record, index) => {
                    const rate = _.get(record, 'data') || _.get(record, 'usePowerRate') || _.get(record, 'useLoadBearRate');
                    return percentFormat(rate);
                }
            }
        ]
    };
    return assignDefaultExtend(chartOptions);
}

export async function ComputerRoomCapacityExportService() {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/computerRoomSystem/rts/export/capacity/count`;
    const res = await download(url, null, 'post');
    return res;
}
