import { Moment } from 'moment';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { assignDefaultExtend } from '@common-src/service/report';
import { QueryModel } from '@common-src/model/query-model';
import ParkingFacilityService from '../service/parking-facility';
import { PeriodTypeEnum, SubSystemType } from '@common-src/model/enum';
import ElevatorIndexService from '../service/elevator-index';
import { dateFormat } from '@common-src/filter';
import { download } from '@common-src/service/request';

export class ElevatorAlarmTrendQueryModel extends QueryModel {
    @QueryControl({
        label: '区域',
        placeholder: '全部区域',
        type: QueryControlType.TREE_SELECT,
        optionsPromise: ParkingFacilityService.getLocationTree,
        optionsPromiseParam: SubSystemType.ELEVATOR,
        mode: 'multiple',
        selectChildren: false,
        span: 10,
        // defaultValue: '[0].value',
        required: false
    })
    locationIds: Array<string> = undefined;

    @QueryControl({
        label: '日期',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        span: 10,
        required: true
    })
    dateRange: Array<Moment> = JTL.CONSTANT.LAST_7_DAY();

    toService() {
        return {
            locationIds: this.locationIds,
            startTime: this.dateRange[0].startOf('day').toDate().getTime(),
            endTime: this.getEndTime(this.dateRange[1]),
            intervalType: PeriodTypeEnum.DAY
        };
    }
}

export async function ElevatorAlarmTrendService(model: ElevatorAlarmTrendQueryModel) {
    const res = await ElevatorIndexService.getLast7DayAlarmCount(model);
    const chartOptions = {
        title: '告警趋势',
        data: res,
        settings: {
            type: 'line'
        },
        tableRowKey: 'ts',
        tableRows: res.rows,
        tableColumns: [
            {
                title: '日期',
                dataIndex: 'ts',
                customRender: (text, record, index) => {
                    return dateFormat(text, 'MM-DD');
                }
            },
            {
                title: '数量',
                dataIndex: '数量'
            }
        ]
    };
    return assignDefaultExtend(chartOptions);
}

export async function ElevatorAlarmTrendExportService(queryModel: ElevatorAlarmTrendQueryModel) {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/elevatorSystem/alarm/hts/export/countTrends`;
    const res = await download(url, queryModel?.toService(), 'post');
    return res;
}
