import { get, post } from '@common-src/service/request';
import { dateFormat } from '@common-src/filter';
import CommonService from '@common-src/service/common';
import { PropertyEntityType } from '@common-src/model/enum';
import { ReportChartHistogramComponent } from '@common-src/mixins/report-chart-base';

const URL_PATH = `${BIBIZ_BASE_REQUEST_PATH}/fireSystem`;
class FireIndexService {
    /**
     * 查询告警数量
     * @returns
     */
    async getAlarmCount() {
        const statusRes = await get(`${URL_PATH}/alarm/rts/STATUS/count`);
        const activeRes = await get(`${URL_PATH}/alarm/rts/ACTIVE/count`);
        const totalCountRes = await get(`${URL_PATH}/alarm/rts/count`);
        return {
            activeCount: activeRes.activeCount,
            unsolvedCount: statusRes.unsolvedCount,
            solvedCount: statusRes.solvedCount,
            totalCount: totalCountRes.totalCount
        };
    }
    /**
     * 设备总数/异常设备
     * @returns
     */
    async getFacilityCount() {
        const url = `${URL_PATH}/facility/rts/count`;
        const res = await get(url);
        return res;
    }

    /**
     * 查询告警状态
     * @returns
     */
    async getAlarmStatus() {
        const statusRes = await get(`${URL_PATH}/alarm/rts/STATUS/count`);
        return {
            columns: ['name', 'value'],
            rows: [
                { name: '已处理', value: statusRes.solvedCount },
                { name: '未处理', value: statusRes.unsolvedCount }
            ]
        };
    }

    /**
     * 设备状态
     * @returns
     */
    async getFacilityStatus() {
        const url = `${URL_PATH}/facility/rts/count`;
        const res = await get(url);
        return {
            columns: ['name', 'value'],
            rows: [
                { name: '异常', value: res.faultCount },
                { name: '正常', value: res.totalCount - res.faultCount }
            ]
        };
    }

    /**
     * 今日告警分布统计
     * @returns
     */
    async getLocationAlarmCount(params?: any) {
        let res = null;
        if (params) {
            const url = `${URL_PATH}/alarm/hts/countByLocation`;
            res = await post(url, params);
        } else {
            const url = `${URL_PATH}/alarm/rts/countByLocation`;
            res = await get(url);
        }
        return {
            data: {
                columns: ['locationName', 'solvedCount', 'unsolvedCount'],
                rows: res
            },
            settings: {
                labelMap: { solvedCount: '已处理', unsolvedCount: '未处理' },
                stack: { stackCount: ['solvedCount', 'unsolvedCount'] }
            },
            extend: {
                series: {
                    label: {
                        show: true,
                        color: '#fff',
                        position: 'inside'
                    }
                }
            }
        };
    }

    /**
     * 近7天告警趋势
     * @returns
     */
    async getLast7DayAlarmCount(params: any) {
        const url = `${URL_PATH}/alarm/hts/countTrends`;
        const res = await post(url, params);
        return {
            columns: ['ts', '数量'],
            rows: _.map(res, item => {
                return {
                    ts: dateFormat(item.ts, 'MM-DD'),
                    '数量': item.value
                };
            })
        };
    }

    /**
     * 告警级别统计
     * @returns
     */
    async getAlarmGradeCount() {
        const eventGradeList = await CommonService.getPropertyOptions(PropertyEntityType.EVENT_GRADE);
        const url = `${URL_PATH}/alarm/rts/GRADE/count`;
        const res = await get(url);
        return {
            columns: ['name', 'value'],
            rows: _.map(_.keys(res), item => {
                return {
                    name: _.get(_.find(eventGradeList, eventGrade => eventGrade.code === item), 'name') || item,
                    value: res[item]
                };
            })
        };
    }

    /**
     * 近7天告警排名TOP5
     * @returns
     */
    async getLast7DayTop5AlarmCount(params: any) {
        const url = `${URL_PATH}/alarm/hts/rankByLocation`;
        const res = await post(url, params);
        return {
            data: {
                columns: ['name', 'value'],
                rows: _.orderBy(_.get(res, 'children'), 'value')
            },
            settings: { labelMap: { value: '数量' } },
            extend: new ReportChartHistogramComponent().HistogramExtend,
            yAxis: {
                type: 'category',
                data: _.map(_.orderBy(_.get(res, 'children'), 'value'), item => item.name),
                axisTick: {
                    show: false
                },
                axisLine: {
                    show: false
                },
                splitLine: {
                    show: false
                }
            },
            xAxis: new ReportChartHistogramComponent().HistogramXAxis
        };
    }
}

export default new FireIndexService();
