import { get, post } from '@common-src/service/request';
import { dateFormat } from '@common-src/filter';
import CommonService from '@common-src/service/common';
import { PropertyEntityType } from '@common-src/model/enum';
import { timeTransformData } from '../data-factory';
import { ReportChartHistogramComponent } from '@common-src/mixins/report-chart-base';

const URL_PATH = `${BIBIZ_BASE_REQUEST_PATH}/custFlowSystem`;
class CustomerFlowIndexService {
    /**
     * 获取留存客流
     * @returns
     */
    async getRetainedFlowCount() {
        const res = await get(`${URL_PATH}/rts/retainedFlow`);
        return res;
    }

    /**
     * 一小时累计流入/流出
     * @returns
     */
    async gethourInOutFlowCount() {
        const res = await get(`${URL_PATH}/hts/hourInOutFlow`);
        return res;
    }

    /**
     * 今天累计客流
     * @returns
     */
    async getDayOfInFlowCount() {
        const res = await get(`${URL_PATH}/hts/dayOfInFlowCount`);
        return res;
    }

    /**
     * 本月累计客流
     * @returns
     */
    async getMonthOfInFlowCount() {
        const res = await get(`${URL_PATH}/hts/monthOfInFlowCount`);
        return res;
    }

    /**
     * 今天留存客流分布
     * @returns
     */
    async getLocationCount(params?: any) {
        const url = `${URL_PATH}/rts/distribution`;
        const res = await post(url, params);
        return {
            columns: ['name', '数量'],
            rows: _.map(res, item => ({ name: item.name, '数量': item.value, rate: item.rate }))
        };
    }

    /**
     * 今天客流趋势
     * @returns
     */
    async getTrendCount(params?: any) {
        const url = `${URL_PATH}/hts/trend`;
        const res = await post(url, params);
        const columns = [
            { code: 'ts', name: '时间' },
            { code: 'inCount', name: '进客流' }
        ];
        const chartData = timeTransformData(res, columns, 'MM-DD', 'locationName');
        return chartData;
    }

    /**
     * 近7天告警排名TOP5
     * @returns
     */
    async getLast7DayTop5AlarmCount(params: any) {
        const url = `${URL_PATH}/alarm/hts/rankByLocation`;
        const res = await post(url, params);
        return {
            data: {
                columns: ['name', 'value'],
                rows: _.orderBy(_.get(res, 'children'), 'value')
            },
            settings: { labelMap: { value: '数量' } },
            extend: new ReportChartHistogramComponent().HistogramExtend,
            yAxis: {
                type: 'category',
                data: _.map(_.orderBy(_.get(res, 'children'), 'value'), item => item.name),
                axisTick: {
                    show: false
                },
                axisLine: {
                    show: false
                },
                splitLine: {
                    show: false
                }
            },
            xAxis: new ReportChartHistogramComponent().HistogramXAxis
        };
    }

    /**
     * 今天告警状态统计
     * @returns
     */
    async getAlarmStatusCount(params?: any) {
        const url = `${URL_PATH}/alarm/rts/STATUS/count`;
        const res = await get(url, params);
        return {
            columns: ['name', '数量'],
            rows: [
                { name: '已处理', '数量': _.get(res, 'solvedCount') || 0 },
                { name: '未处理', '数量': _.get(res, 'unsolvedCount') || 0 }
            ]
        };
    }

    /**
     * 近7天告警趋势
     * @returns
     */
    async getLast7DayAlarmCount() {
        const url = `${URL_PATH}/alarm/rts/countTrends`;
        const res = await get(url);
        return {
            columns: ['ts', '数量'],
            rows: _.map(res, item => {
                return {
                    ts: dateFormat(item.ts, 'MM-DD'),
                    '数量': item.value
                };
            })
        };
    }

    /**
     * 告警级别统计
     * @returns
     */
    async getAlarmGradeCount() {
        const eventGradeList = await CommonService.getPropertyOptions(PropertyEntityType.EVENT_GRADE);
        const url = `${URL_PATH}/alarm/rts/GRADE/count`;
        const res = await get(url);
        return {
            columns: ['name', 'value'],
            rows: _.map(_.keys(res), item => {
                return {
                    name: _.get(_.find(eventGradeList, eventGrade => eventGrade.code === item), 'name') || item,
                    value: res[item]
                };
            })
        };
    }
}

export default new CustomerFlowIndexService();
