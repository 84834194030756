import { Moment } from 'moment';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { assignDefaultExtend } from '@common-src/service/report';
import { QueryModel } from '@common-src/model/query-model';
import ParkingIndexService from '../service/parking-index';
import ParkingFacilityService from '../service/parking-facility';
import { changeTreeDataFieldName, getTreeIdByDepth } from '../data-factory';
import { ParkingGateType, PeriodTypeEnum, SubSystemType } from '@common-src/model/enum';
import { download } from '@common-src/service/request';

export class ParkingTrafficFlowQueryModel extends QueryModel {
    @QueryControl({
        label: '区域选择',
        type: QueryControlType.TREE_SELECT,
        optionsPromise: ParkingFacilityService.getLocationTree,
        optionsPromiseParam: SubSystemType.PARKING,
        mode: 'multiple',
        selectChildren: true,
        index: 10,
        span: 8,
        defaultValue: 'ALL',
        required: true
    })
    locationIds: Array<string> = undefined;

    @QueryControl({
        label: '日期范围',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        span: 10,
        required: true
    })
    dateRange: Array<Moment> = JTL.CONSTANT.TODAY();

    toService() {
        return {
            locationIds: this.locationIds,
            startTime: this.dateRange[0].startOf('day').toDate().getTime(),
            endTime: this.getEndTime(this.dateRange[1]),
            types: [ParkingGateType.ENTRANCE, ParkingGateType.EXIT],
            intervalType: PeriodTypeEnum.HOUR,
            merge: true
        };
    }
}

export async function ParkingTrafficFlowService(model: ParkingTrafficFlowQueryModel) {
    const trafficFlowData = await ParkingIndexService.getTrafficFlow(model);
    const chartOptions = {
        title: '车流量统计',
        data: {
            columns: trafficFlowData.columns,
            rows: trafficFlowData.rows
        },
        settings: {
            type: 'line',
            labelMap: { inCount: '驶入', outCount: '驶出' }
        },
        extend: {
            series: {
                label: {
                    show: false,
                    position: 'top'
                }
            }
        },
        tableRowKey: 'locationId',
        tableExpandedRowKeys: getTreeIdByDepth(trafficFlowData.response, 'locationId', 1),
        tableRows: changeTreeDataFieldName(trafficFlowData.response, 'data', 'children'),
        tableColumns: [
            {
                title: '时间',
                dataIndex: 'ts',
                customRender: (text, record, index) => {
                    return text || record.locationName;
                }
            },
            {
                title: '驶入',
                dataIndex: 'inCount'
            },
            {
                title: '驶出',
                dataIndex: 'outCount'
            }
        ]
    };
    return assignDefaultExtend(chartOptions);
}

export async function ParkingTrafficFlowExportService(queryModel: ParkingTrafficFlowQueryModel) {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/parkingSystem/parkingGate/export/trafficFlow`;
    const res = await download(url, queryModel?.toService(), 'post');
    return res;
}
